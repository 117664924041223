export const dateFormatter = (value) => {
    return new Intl.DateTimeFormat(
        'en-US',
        {dateStyle: 'short', timeStyle: 'short'}
    ).format(new Date(value));
};

export const dateDayFormatter = (value) => {
    return new Intl.DateTimeFormat(
        'en-US',
        {dateStyle: 'long', timeStyle: undefined}
    ).format(new Date(value));
};

export const timeSpentFormatter = (value: number): string => {
    if (value <= 0) {
        return '';
    }

    if (value < 60) {
        return value + 'm';
    }

    let hours = value / 60;
    hours = Math.floor(hours);
    const minutes = value - (hours * 60);
    let formatted = `${hours}h`;

    if (minutes > 0) {
        formatted += ` ${minutes}m`;
    }

    return formatted;
};