<script setup lang="ts">
import { ClockIcon } from "@heroicons/vue/24/solid";
import { useNuxtApp } from "#app";
import { ResizeType } from "@bitpatty/imgproxy-url-builder";

const props = defineProps({
    title: String,
    time: String,
    category: String,
    pictureUrl: String,
    detailUrl: String,
    skeleton: Boolean,
    notLazyLoad: Boolean,
    targetBlank: Boolean
});
const nuxtApp = useNuxtApp();
</script>

<template>
    <nuxt-link v-if="skeleton === false" :to="detailUrl" :target="targetBlank ? '_blank' : ''" class="block bg-gray-100 p-4 sm:p-6 rounded-lg space-y-2 md:space-y-4">
        <picture v-if="pictureUrl" class="block relative">
            <source media="(max-width: 766px)" type="image/webp" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 115, 115, 'webp', ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 230, 230, 'webp', ResizeType.FILL)} 2x`">
            <source media="(max-width: 766px)" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 115, 115, undefined, ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 230, 230, undefined, ResizeType.FILL)} 2x`">
            <source media="(min-width: 767px)" type="image/webp" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 240, 240, 'webp', ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 480, 480, 'webp', ResizeType.FILL)} 2x`">
            <source media="(min-width: 767px)" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 240, 240, undefined, ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 480, 480, undefined, ResizeType.FILL)} 2x`">
            <img
                :loading="notLazyLoad ? 'eager' : 'lazy'"
                width="240"
                height="240"
                class="object-cover h-full w-full object-center rounded bg-soft-white-100"
                :src="nuxtApp.imgProxyResize(pictureUrl, 115, 115, undefined, ResizeType.FILL)"
                :alt="title"
            >
            <span v-if="time" class="bg-orange-100 absolute bottom-1 right-1 rounded px-1 flex items-center text-sm">
                <ClockIcon class="h-4 w-4 mr-[0.2rem]" />{{ time }}
            </span>
        </picture>
        <h3 v-if="category" class="tracking-widest text-indigo-800 text-xs font-medium uppercase title-font">{{ category }}</h3>
        <h2 class="text-sm sm:text-lg sm:leading-6 text-gray-900 font-medium title-font">{{ title }}</h2>
<!--        <div class="flex items-center">
            <div class="flex items-center">
                <StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[rating < 4 ? 'text-orange-400' : 'text-orange-200', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
            </div>
            <p class="sr-only">4 out of 5 stars</p>
            <a href="#" class="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">117 reviews</a>
        </div>-->
    </nuxt-link>
    <div v-else class="block p-4 sm:p-6 rounded-lg space-y-2 md:space-y-4 bg-gray-50">
        <picture class="block relative mb-6">
            <div class="animate-pulse h-20 sm:h-40 w-full object-center rounded bg-soft-white-100"></div>
            <span class="animate-pulse h-4 w-11 bg-orange-50 absolute bottom-1 right-1 rounded px-1"></span>
        </picture>
        <h3 class="animate-pulse rounded h-2 w-[40%] bg-indigo-50"></h3>
        <h2 class="animate-pulse rounded h-4 w-[70%] bg-gray-100"></h2>
    </div>
</template>